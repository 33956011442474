<template>
  <div :class="{'m-table-icons':true,'exit':!!$slots.btns}">
<!--    <div class="u-icons">-->
<!--      <el-dropdown trigger="click" @command="funDrop">-->
<!--        <span class="u-icon el-icon-c-scale-to-original" title="密度"></span>-->
<!--        <el-dropdown-menu slot="dropdown">-->
<!--          <el-dropdown-item command="medium">默认</el-dropdown-item>-->
<!--          <el-dropdown-item command="small">较小</el-dropdown-item>-->
<!--          <el-dropdown-item command="mini">紧凑</el-dropdown-item>-->
<!--        </el-dropdown-menu>-->
<!--      </el-dropdown>-->
<!--      <span :class="['u-icon el-icon-full-screen',full?'':'']" @click="funFull"></span>-->
<!--      <el-popover-->
<!--        placement="bottom"-->
<!--        width="150"-->
<!--        trigger="click"-->
<!--      >-->
<!--        <div class="u-pop-content">-->
<!--          <el-checkbox-group v-model="checked" @change="funCheckChange">-->
<!--            <el-checkbox-->
<!--              class="u-checkbox" v-for="item in columns" :label="item.key"-->
<!--              :key="item.key"-->
<!--            >-->
<!--              {{ item.columnLabel }}-->
<!--            </el-checkbox>-->
<!--          </el-checkbox-group>-->
<!--        </div>-->
<!--        <span class="u-icon el-icon el-icon-s-operation" slot="reference" title="列设置"></span>-->
<!--      </el-popover>-->
<!--    </div>-->
    <slot name="btns">
    </slot>
  </div>
</template>
<script>
export default {
  props: {
    columns: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      full: false,
      checked: []
    }
  },
  created () {
    this.checked = this.columns.map(o => o.key)
  },
  mounted () {
    document.querySelector('#tableRef').addEventListener('fullscreenchange', event => {
      if (document.fullscreenElement) {
        this.full = true
      } else {
        this.full = false
      }
    })
  },
  methods: {
    funDrop (val) {
      this.$emit('sizeChange', val)
    },
    funCheckChange (val) {
      if (val.length === 0) return false
      const res = this.columns.filter(o => val.includes(o.key))
      this.$emit('columnChange', res)
    },
    funFull () {
      if (!document.fullscreenElement) {
        document.querySelector('#tableRef').requestFullscreen()
        document.querySelector('.m-page').style.left = '0'
        document.querySelector('.m-page').style.width = '100%'
        this.full = true
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen()
          document.querySelector('.m-page').style.left = 'unset'
          document.querySelector('.m-page').style.width = 'calc(100% - 270px)'
          this.full = false
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.m-table-icons{
  display:flex;
  justify-content: flex-end;
  align-items: center;
  &.exit{
    justify-content: space-between;
  }
  .u-icons{
    width:100px;
    display:flex;
    margin-left: 20px;
    margin-bottom:20px;
    justify-content: space-between;
    align-items: center;
    .u-icon{
      cursor: pointer;
      font-size:18px;
    }
  }
}
.u-checkbox{
  display:block;
  text-align:left;
  width:100%;
  height:30px;
}
.u-pop-content{
  text-align:center;
}
</style>
